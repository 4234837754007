@font-face {
    font-family: Brera;
    src: url(./assets/GraphikRegular.otf);
    font-weight: normal;
}

@font-face {
    font-family: Brera;
    src: url(./assets/GraphikBold.otf);
    font-weight: bold;
}