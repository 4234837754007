body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;

  background-color: #4D0C79;
  background-image: url(./background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}


html, body, #root {
  width: 100%;
  height: 100%;
}

/*#root {
  background: linear-gradient(45deg, #09B4B3, #6FB6B3, #09B4B3, #6FB6B3, #09B4B3);
}*/

.space-between {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
}

.space-around {
  display: flex !important;
  flex-direction: column;
  justify-content: space-around;
}

.speakers-container {
  background-color: rgba(20, 12, 56, 0.5);
}

.login-header {
  font-family: Brera, Arial;
  font-weight: 900;
  font-size: 28px;
  color: #FFFFFF;
  margin-bottom: 0;
}

.login-subheader {
  font-family: Brera, Arial;
  font-weight: 500;
  font-size: 28px;
  color: #FFFFFF;
  margin: 0 0 10px 0;
}

.login-header-note {
  font-family: Brera, Arial;
  font-size: 16px;
  color: #FFFFFF;
}

.login-button {
  background-color: #81139B !important;
}

.login-container {
  background-color: #310864 !important;
}